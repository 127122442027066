@font-face {
  font-family: "Bookmania";
  font-weight: 400;
  src: url("./bookmania/regular/Bookmania-Regular.eot");
  src: url("./bookmania/regular/Bookmania-Regular.eot?#iefix") format("embedded-opentype"),
    url("./bookmania/regular/Bookmania-Regular.svg#Bookmania-Regular") format("svg"),
    url("./bookmania/regular/Bookmania-Regular.woff") format("woff"),
    url("./bookmania/regular/Bookmania-Regular.woff2") format("woff2");
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Bookmania";
  font-weight: 900;
  src: url("./bookmania/black/Bookmania-BlackItalic.eot");
  src: url("./bookmania/black/Bookmania-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("./bookmania/black/Bookmania-BlackItalic.svg#Bookmania-BlackItalic") format("svg"),
    url("./bookmania/black/Bookmania-BlackItalic.woff") format("woff"),
    url("./bookmania/black/Bookmania-BlackItalic.woff2") format("woff2");
  font-style: italic;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Bookmania";
  font-weight: 900;
  src: url("./bookmania/black/Bookmania-Black.eot");
  src: url("./bookmania/black/Bookmania-Black.eot?#iefix") format("embedded-opentype"),
    url("./bookmania/black/Bookmania-Black.svg#Bookmania-Black") format("svg"),
    url("./bookmania/black/Bookmania-Black.woff") format("woff"),
    url("./bookmania/black/Bookmania-Black.woff2") format("woff2");
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Bookmania";
  font-weight: 700;
  src: url("./bookmania/bold/Bookmania-BoldItalic.eot");
  src: url("./bookmania/bold/Bookmania-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./bookmania/bold/Bookmania-BoldItalic.svg#Bookmania-BoldItalic") format("svg"),
    url("./bookmania/bold/Bookmania-BoldItalic.woff") format("woff"),
    url("./bookmania/bold/Bookmania-BoldItalic.woff2") format("woff2");
  font-style: italic;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Bookmania";
  font-weight: 400;
  src: url("./bookmania/regular/Bookmania-RegularItalic.eot");
  src: url("./bookmania/regular/Bookmania-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("./bookmania/regular/Bookmania-RegularItalic.svg#Bookmania-RegularItalic") format("svg"),
    url("./bookmania/regular/Bookmania-RegularItalic.woff") format("woff"),
    url("./bookmania/regular/Bookmania-RegularItalic.woff2") format("woff2");
  font-style: italic;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Bookmania";
  font-weight: 700;
  src: url("./bookmania/bold/Bookmania-Bold.eot");
  src: url("./bookmania/bold/Bookmania-Bold.eot?#iefix") format("embedded-opentype"),
    url("./bookmania/bold/Bookmania-Bold.svg#Bookmania-Bold") format("svg"),
    url("./bookmania/bold/Bookmania-Bold.woff") format("woff"),
    url("./bookmania/bold/Bookmania-Bold.woff2") format("woff2");
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Bookmania";
  font-weight: 300;
  src: url("./bookmania/light/Bookmania-Light.eot");
  src: url("./bookmania/light/Bookmania-Light.eot?#iefix") format("embedded-opentype"),
    url("./bookmania/light/Bookmania-Light.svg#Bookmania-Light") format("svg"),
    url("./bookmania/light/Bookmania-Light.woff") format("woff"),
    url("./bookmania/light/Bookmania-Light.woff2") format("woff2");
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Bookmania";
  font-weight: 300;
  src: url("./bookmania/light/Bookmania-LightItalic.eot");
  src: url("./bookmania/light/Bookmania-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("./bookmania/light/Bookmania-LightItalic.svg#Bookmania-LightItalic") format("svg"),
    url("./bookmania/light/Bookmania-LightItalic.woff") format("woff"),
    url("./bookmania/light/Bookmania-LightItalic.woff2") format("woff2");
  font-style: italic;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Bookmania";
  font-weight: 600;
  src: url("./bookmania/semibold/Bookmania-Semibold.eot");
  src: url("./bookmania/semibold/Bookmania-Semibold.eot?#iefix") format("embedded-opentype"),
    url("./bookmania/semibold/Bookmania-Semibold.svg#Bookmania-Semibold") format("svg"),
    url("./bookmania/semibold/Bookmania-Semibold.woff") format("woff"),
    url("./bookmania/semibold/Bookmania-Semibold.woff2") format("woff2");
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

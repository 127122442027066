body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Global theme style - Export from @avenue-8/ui-2 */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

@font-face {
  font-family: "Canela";
  src: url("../modules/shared/fonts/canela/Canela-Light.otf") format("opentype");
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

[class^="MuiAutocomplete-option"][aria-selected="true"] {
  background-color: initial;
}

[class^="MuiAutocomplete-option"][aria-selected="true"][data-focus="true"] {
  background-color: rgba(0, 0, 0, 0.04);
}

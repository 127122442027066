/* Not ideal - we may keep the editor skin with the original theme for now */
.tox .tox-button:not(.tox-button--icon) {
  font-weight: 500 !important;
  text-transform: uppercase !important;
  font-size: 12px uppercase !important;
  letter-spacing: 2px uppercase !important;
  line-height: 1.5rem uppercase !important;
  background: #fff !important;
  border: 1px solid #e5e5e5 !important;
  color: #000 !important;
  padding: 11px 18px !important;
  border-radius: 4px !important;
  border: 1px solid #131319 !important;
  font-family: "Roboto" !important;
  font-size: 12px !important;
  letter-spacing: 2px !important;
  min-width: 100px !important;
}

.tox .tox-collection__item-label {
  line-height: initial !important;
}
/* Slide */
@keyframes animate-slide-in-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes animate-slide-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Fade */
@keyframes animate-fade-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes  animate-fade-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}